<template>
<div class="d-flex align-items-end flex-column h-100">
  <div class="d-flex align-items-center flex-wrap">
    <!--begin::Search Form-->
    <div class="d-flex align-items-center w-100 pb-3" id="kt_subheader_search">
      <form class="w-100" @submit.stop.prevent="">
        <b-input-group>
          <InputForm
            v-model="search"
            id="kt_subheader_search_form"
            trim
            type="text"
            debounce="500"
            :placeholder="$t('BASE.SEARCH')"
          />
          <b-input-group-append>
            <span class="input-group-text border-0">
              <span class="svg-icon">
                <inline-svg src="/media/svg/icons/General/Search.svg" />
              </span>
            </span>
          </b-input-group-append>
        </b-input-group>
        <b-form-group class="mt-3">
          <SelectTree
            v-model="params.categories"
            :items="itemsCategory"
            :placeholder="$t('PLACEHOLDER.CATEGORY')"
            multiple
            flat
            searchable
            clearable
          />
        </b-form-group>
      </form>
    </div>
    <!--end::Search Form-->
  </div>
  <Table tbody-tr-class="cursor-pointer" :fields="fields" :items="items" :busy.sync="loading" @row-clicked="rowClicked">
    <template #cell(selected)="data">
      <div v-if="isSelect(data.item.uuid)" style="min-width: 20px">
        <span class="svg-icon btn-light-success">
          <inline-svg src="/media/svg/icons/Navigation/Check.svg" />
        </span>
      </div>
    </template>
    <template #cell(name)="data">
      <div style="min-width: 140px">
        <span class="text-dark-75 font-weight-bolder font-size-lg mb-0">{{ data.item.name }}</span>
      </div>
    </template>
    <template #cell(cost)="data">
      <div style="min-width: 80px">
        <span class="text-dark-75 font-weight-bolder font-size-lg mb-0">
          <template v-if="data.item.currentPrice && (data.item.currentPrice.cost || data.item.cost)">
            {{ (data.item.currentPrice.cost || data.item.cost) | centsToDollars }}
          </template>
          <template v-else>
            {{ $t('SERVICES_LIST.NO_PRICE') }}
          </template>
        </span>
      </div>
    </template>
  </Table>
  <div v-if="totalCount && params.pagination.limit < totalCount" class="mt-3 w-100">
    <Paginations v-model="params.pagination.page"
                 :total-rows="totalCount"
                 limit="3"
                 :per-page="params.pagination.limit"
                 class="text-sm-center"
    />
  </div>
  <div class="d-flex align-items-center pb-3 w-100">
    <span class="svg-icon svg-icon-lg svg-icon-warning" :style="{'visibility': isWarningPrice ? 'visible' : 'hidden'}">
      <inline-svg src="/media/svg/icons/Code/Warning-2.svg" />
    </span>
    <span class="text-warning px-3" :style="{'visibility': isWarningPrice ? 'visible' : 'hidden'}">
      {{ $t('VALIDATION.ORDER.CANT_ADD_SERVICE_WITHOUT_PRICE') }}
    </span>
  </div>
  <div class="mt-auto pb-3 pb-sm-0 w-100">
    <b-button
      :disabled="isWarningPrice"
      @click="onSubmit"
      class="w-100 d-flex align-items-center justify-content-center btn btn-light-success font-weight-bold btn-sm"
    >
      <span class="svg-icon btn-light-success">
        <inline-svg src="/media/svg/icons/Files/File-plus.svg" />
      </span>
      {{ $t('FORM.SUBMIT') }}
    </b-button>
    <div class="fake-element">
      <!--      IOS fix-->
    </div>
  </div>
</div>
</template>

<script>
import Repo from '@/core/repository/company/servicesRepository'
import RepoServiceCategory from '@/core/repository/company/serviceCategoriesRepository'
import Paginations from '../../../UI/Paginations'
import InputForm from '../../../forms-items/input'
import Table from '../../../UI/Table'
import SelectTree from '../../../forms-items/SelectTree'

export default {
  name: 'OrderServiceForm',
  components: {
    SelectTree,
    Table,
    InputForm,
    Paginations, 
  },
  props: {
    handlers: {
      type: Object,
      default: () => {
      },
    },
    orders: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selected: [],
      search: '',
      params: {
        categories: null,
        pagination: {
          page: 1,
          limit: 10,
        },
        order: {
          createdAt: 'desc',
        },
      },
      totalCount: null,
      loading: false,
      fields: [
        {
          key: 'name',
          label: this.$t('SERVICES_LIST.NAME'),
          sortable: false,
        },
        {
          key: 'cost',
          label: this.$t('SERVICES_LIST.COST'),
          sortable: false,
        },
        {
          key: 'selected',
          tdClass: 'w-20 px-0',
          label: '',
          sortable: false,
        },
      ],
      items: [],
      itemsCategory: [],
    }
  },
  computed: {
    isSelect() {
      return (uuid) => this.selected.map((m) => m.uuid).includes(uuid)
    },
    Repo() {
      return Repo
    },
    isWarningPrice() {
      return this.selected.some((item) => !(item.currentPrice && (item.currentPrice.cost || item.cost)))
    },
  },
  watch: {
    'params.pagination.page': {
      handler() {
        this.fetchData()
      },
    },
    'params.categories': {
      handler() {
        this.fetchData()
      },
      deep: true,
    },
    search(v) {
      this.params.search = v
      if (!v) delete this.params.search
      this.reFetchData()
    },
  },
  created() {
    this.selected = JSON.parse(JSON.stringify(this.orders))
    this.fetchData()
    this.fetchFilter()
  },
  methods: {
    fetchFilter() {
      RepoServiceCategory.getTree().then(({ data }) => {
        this.itemsCategory = data.payload
      })
    },
    rowClicked(item) {
      item.type = 'service'
      const index = this.selected.findIndex((f) => f.uuid === item.uuid)
      if (index >= 0) {
        this.selected.splice(index, 1)
      } else {
        this.selected.push(item)
      }
    },
    reFetchData() {
      this.params.pagination.page = 1
      this.fetchData()
    },
    fetchData() {
      this.loading = true
      const params = JSON.parse(JSON.stringify(this.params))
      params.categories = params.categories ? params.categories.map((m) => m.uuid) : params.categories
      this.Repo.list(params).then(({ data }) => {
        this.items = data.payload.items
        this.totalCount = data.payload.pagination.totalCount
        this.loading = false
      })
    },
    onSubmit() {
      const payload = JSON.parse(JSON.stringify(this.selected))
      this.selected = []
      this.$emit('onSubmit', payload)
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
